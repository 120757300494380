import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const PicMeInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about Pic Me</h2>
      <p>
        Can you identify bats from the birds without blinking an eye? Do you
        possess that picture perfect memory that remembers every logo on the
        billboards?
      </p>
      <p>
        Remembering the tiny details, spotting the odd one out or finding the
        right face in a crowd - our memory and observation are superhuman skill
        that we never use to its maximum strengths. What if you could test your
        extraordinary superpowers against other humans and win exciting prizes?
        What if you could use your sharp memory, keen eyes and strong reflexes
        to challenge others in a battle of wit and speed, and earn real cash
        while having fun?
      </p>
      <p>
        Play PicMe on Mega, India’s hottest real money gaming app. Put your
        quick observation skills to test, and beat your opponents in a fun
        multiplayer picture quiz to earn real money. 24x7.
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Mega PicMe is played against real players in real time. You compete with
        2-3 players at a time, to solve picture based questions.
      </p>
      <p>
        ‘Can you name this logo?’ ‘How many cars do you see in the picture?’
        ‘How many unique objects do you see?’
      </p>
      <p>
        The fun begins as soon as the timer for the question starts. The
        questions in PicMe are generated through a systematic process, so no set
        of questions get repeated, and it is truly a game of your skill. Pick
        the right option the fastest, and beat your opponents with our fun
        picture quiz and win exciting cash prizes.
      </p>

      <h3>How to Play</h3>
      <ul>
        <li>
          <p>Download Mega. Choose PicMe in Trivia.</p>
        </li>
        <li>
          <p>
            Pick any table. The buy-in amounts start from as as low as ₹1. So
            you can comfortably play at a table that suits you. Tables run 24x7
            and all games are played between 2-3 players in real-time.
          </p>
        </li>
        <li>
          <p>
            Pick the right answer the fastest. Beat others and take away the
            prize. Play any number of rounds on a table.
          </p>
        </li>
        <li>
          <p>
            The winnings are credited to the winnings wallet as soon as you
            leave the table.
          </p>
        </li>
        <li>
          <p>Spread the joy.</p>
        </li>
      </ul>

      <h3>Rules</h3>
      <ul>
        <li>
          <p>All players start with an equal number of points.</p>
        </li>
        <li>
          <p>
            To win PicMe, a player must ‘race to zero’ - reduce points to reach
            zero the fastest.
          </p>
        </li>
        <li>
          <p>
            The picture quiz is posed in a multiple choice format. Tap the right
            answer faster than your opponents to reduce a point. Time taken to
            solve the problem decides who gets the point.
          </p>
        </li>
        <li>
          <p>
            The round is completed whenever any player answers enough questions
            to reach zero. The total points of the remaining players are added
            to decide the final score.
          </p>
        </li>
        <li>
          <p>
            The score multiplied by the cash point is the total winnings of the
            player, after deduction of the rake amount (a very small percentage
            taken by the platform).
          </p>
        </li>
      </ul>

      <h3>Fun questions. True skills.</h3>
      <p>
        The questions and multiple choice options in Mega PicMe are generated by
        a system, so that there is no repetition of any sets of questions.
        Counting cars, guessing the logos or finding the odd one out out has
        never been. With the time hanging over everyone, PicMe is truly a game
        of skill and an exciting picture based trivia game to earn real money.
      </p>

      <h3>Multiplayer games. Round the clock.</h3>
      <p>
        PicMe is a played in a multiplayer format, with 100% verified profiles.
        Only a limited number of players are allowed on each table. And tables
        run 24x7, so you don’t have you to wait to play your favourite logo and
        picture quiz to win real money prizes.
      </p>

      <h3>Instant Wins. Instant Withdrawals.</h3>
      <p>
        You don’t have to wait hour or minutes for the game to finish, or wait
        for some leaderboard ranking to win real money. The total amount won in
        PicMe rounds is instantly transferred to the Winnings wallet as soon as
        you leave the table. And the winnings can be easily withdrawn to your
        Paytm wallet, through UPI or directly to your bank account, all within a
        minute. Need for speed. Right?
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        hidden={hidden}
        gameName="PicMe"
      />
    </div>
  )
}

export default PicMeInfo
