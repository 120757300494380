import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import '../../Cards/Poker/Poker.scss'
import { Container } from 'react-bootstrap'
import GamesIntro from '../../GamesIntro/GamesIntro'
import Contests from '../../Contests'
import GameTestimonials from '../../Cards/Poker/GameTestimonials'
import OtherGames from '../../Cards/Poker/OtherGames'
import DownloadBottomSheet from '../../../Download/DownloadBottomSheet'
import DesktopTitle from './DesktopTitle'
import PicMeInfo from '../../CategoryAndGamesInfo/PicMeInfo'
import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import { Faq } from '../../../FAQs/faqs-list-interface'
import useTestimonials from '../../../Testimonials/useTestimonials'
import { PICME } from '../../../download-links'
import InfoCard from '../../CategoryAndGamesInfo/InfoCard'
import { recsGameNames } from '../../Cards/Poker/useRecommendGames'
import PaymentPartners from '../../../Index/PaymentPartners/PaymentPartners'
import Features from '../../../Index/Features/Features'
import SafeAndCertified from '../../../Index/SafeAndCertified/SafeAndCertified'

const PicMe: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/pic me.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [picmeFaqsSt, setPicmeFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../FAQs/faqs-list').then(({ picmeFaqs }) =>
      setPicmeFaqsSt(picmeFaqs)
    )
  }, [])

  const [showDownload, setShowDownload] = useState(false)

  const hideBottomSheet = (): void => {
    setShowDownload(false)
  }

  const { picmeTestimonials } = useTestimonials()

  return (
    <div id="poker">
      <GamesIntro
        thumbnail={data.intro.childImageSharp.gatsbyImageData}
        gameName="Pic Me"
        apkLink={PICME}
      />
      <DesktopTitle />
      <Container>
        <InfoCard>
          <PicMeInfo />
        </InfoCard>
      </Container>
      <Contests gameName="PicMe" />
      <PaymentPartners />
      <Features />
      <SafeAndCertified hideGame />
      <GameTestimonials testimonials={picmeTestimonials} />
      <div className="faqs-wrap">
        <HomeFAQs faqs={picmeFaqsSt} />
      </div>
      <OtherGames gameName={recsGameNames.PicMe} category="Trivia" />
      <DownloadBottomSheet
        show={showDownload}
        hideBottomSheet={hideBottomSheet}
        downloadLink={PICME}
      />
    </div>
  )
}

export default PicMe
