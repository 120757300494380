import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PicMePage from '../../components/Games/Trivia/PicMe/PicMe'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  GAMES,
  TRIVIA,
  PICME as PICME_LINK,
} from '../../components/internal-links'
import { PICME } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Trivia',
    url: TRIVIA,
  },
  {
    title: 'Pic Me',
    url: PICME_LINK,
  },
]

const PicMe: React.FC = () => {
  const [picmeFaqsSt, setPicmeFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ picmeFaqs }) =>
      setPicmeFaqsSt(picmeFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      apkLink={PICME}
    >
      <SEO
        title="Play PicMe: Identify Logos, Objects & More to Earn Money | Mega "
        description="Play exciting logo quizzes and picture puzzles to win real money. Download Mega and Play PicMe. Defeat your opponents with your observation skills and earn real cash."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(picmeFaqsSt)}
      />
      <PicMePage />
    </Layout>
  )
}

export default PicMe
